import React from "react";
import Layout from "../../../../components/Layout/layout";
import WeekendTripPage from "../../../../components/WeekendTrip-blog/index";
import SEO from "../../../../components/SEO/seo";
const BlogDetail = () => {
	return (
		<Layout>
			<SEO
				title="Taxi service worth a weekend trip - perfect in Hamburg"
				meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
				description="Taxi service proves to be a cost-effective way of visiting the places at the weekend. You can book the taxi for a comfortable ride to visit the nearest locations."
			/>
			<WeekendTripPage />
		</Layout>
	);
};

export default BlogDetail;
